const Suryong = {
    "언덕룡": {
        title: '언덕룡',
        subtitle: '언덕 오르는 수룡',
        desctitle: '철저한 계획형 수룡이',
        desc: `안정성과 신뢰성을 중요하게 여겨요. \n하려고 한 것은 무조건 해야 해요. \n항상 기본적인 규칙을 지키려고 노력해요.`,
        suggestion: '푸드트럭 구경하기'
    },
    "여유룡": {
        title: '언덕룡',
        subtitle: '여유를 즐기는 수룡',
        desctitle: '톡톡 튀는 매력의 수룡이',
        desc: `직설적이고 실용적인 성격이에요. \n복잡한 생각을 안 해요. \n하고 싶은 건 해야 직성이 풀려요.`,
        suggestion: '운정뜰 포토존 즐기기'
    },
    "투어룡": {
        title: '투어룡',
        subtitle: '스탬프 투어하는 수룡',
        desctitle: '현실적으로 생각하는 수룡이',
        desc: `맡은 일에 최선을 다해요. \n위기 대처 능력이 빨라요. \n문제 해결과 효율적인 업무처리를 잘해요.`,
        suggestion: '스탬프 투어 하기'
    },
    "실속룡": {
        title: '실속룡',
        subtitle: '제휴 키트 든 수룡',
        desctitle: '시크한 수룡이',
        desc: `효율적인 것을 중요하게 여겨요. \n혼자 있는 시간이 더 좋아요. \n자율적으로 일하는 환경에서 능력을 잘 발휘해요.`,
        suggestion: '제휴 부스 방문하기'
    },
    "타투룡": {
        title: '타투룡',
        subtitle: '타투하는 수룡',
        desctitle: '열정 넘치는 수룡이',
        desc: `사람과의 관계에서 힘을 얻어요. \n주위 사람들에게 항상 긍정적인 에너지를 줘요. \n도전 정신이 강하고, 끈기 있게 꾸준히 노력해요.`,
        suggestion: '타투 스티커 붙이기'
    },
    "독서룡": {
        title: '독서룡',
        subtitle: '책 읽는 수룡',
        desctitle: '신뢰받는 수룡이',
        desc: `낯을 가리고, 때로는 사람을 만나는 게 귀찮아요. \n그러나 내 사람한테는 한없이 따뜻해요. \n관계에 헌신적이며 신뢰를 중요하게 여겨요.`,
        suggestion: '플리마켓 구경하기'
    },
    "가수룡": {
        title: '가수룡',
        subtitle: '무대 위의 수룡',
        desctitle: '개성이 넘치는 수룡이',
        desc: `호불호가 강하고, 취향이 잘 변하지 않아요. \n얽매이지 않는 새로운 방식을 추구해요. \n자신의 삶을 진취적으로 변화시켜요.`,
        suggestion: '무대 프로그램 즐기기'
    },
    "게임룡": {
        title: '게임룡',
        subtitle: '게임하는 수룡',
        desctitle: '쿨민트 수룡이',
        desc: `뒤끝 없이 시원시원한 성격이에요. \n완벽주의적 성향이 있어 뭐든지 최선을 다해요. \n사람과의 관계에서 성취감과 만족을 추구해요.`,
        suggestion: '놀이기구 타기'
    }
}

export default Suryong;