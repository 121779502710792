const AllText = [
    {
        question: '제대로 놀기 위해서 나는 언제 출발할까?',
        desc: '오늘은 9월 25일 수정대동제의 첫날이다. 수룡이로 변해버렸지만, 축제를 포기할 수는 없다.',
        choice: [
            {
                text: '최대한 빨리 가야지',
                score: [1, 8]
            },
            {
                text: '준비되는 대로 나가지 뭐',
                score: [2, 7]
            },
            {
                text: '밤에 가서 공연만 즐기고 오자',
                score: [4]
            }
        ]
    },
    {
        question: '로데오 거리를 걷다가 익숙한 뒷모습이 보인다.',
        desc: '엇..? 아는 사람 같기도 하고... 이때의 나는?',
        choice: [
            {
                text: '아닐 수도 있지만 일단 인사해볼까?',
                score: [3, 5, 7]
            },
            {
                text: '아니면 곤란하니까 그냥 가야지...',
                score: [2, 4, 6]
            }
        ]
    },
    {
        question: '알고 보니 과 동기 수정이가 소개해 준 친구였다.',
        desc: '마침, 오늘 그 수정이와 만난다고 한다. “너도 같이 갈래?”',
        choice: [
            {
                text: '앗 나는 이미 선약이 있어!',
                score: [2]
            },
            {
                text: '(혼자가 더 편한데...) 괜찮아~',
                score: [4, 6]
            },
            {
                text: '(약속도 없었는데 잘 됐다) 좋아!',
                score: [5, 7]
            }
        ]
    },
    {
        question: '설레는 마음으로 걷다 보니 어느새 학교 정문이 보인다.',
        desc: '이때 나는?',
        choice: [
            {
                text: '같이 만나기로 한 친구에게 연락해 본다',
                score: [1, 5, 8]
            },
            {
                text: '혼자서 편하게 다닌다',
                score: [4, 6, 7]
            }
        ]
    },
    {
        question: '이번 축제에서는 온라인으로 스탬프 투어를 진행한다고?',
        desc: '축제 홈페이지에서 스탬프 투어 지도를 켰다!',
        choice: [
            {
                text: '효율적으로 돌아다니려면 이쪽부터 가면 되겠다',
                score: [1, 3, 4]
            },
            {
                text: '헉 이거 재밌겠다... 조금 멀지만 일단 가보자!',
                score: [2, 7, 8]
            }
        ]
    },
    {
        question: '총학생회 부스에서 게임해야지!',
        desc: '와 당첨! 크리스탈 존 매직패스잖아!!',
        choice: [
            {
                text: '재밌었다~ 이제 다른 부스도 구경해야지',
                score: [1, 3, 8]
            },
            {
                text: '오늘 느낌 좋은데..! 한 번 더~!',
                score: [5, 7]
            }
        ]
    },
    {
        question: '크리스탈 존 매직패스 대신 상품권으로 교환할 수도 있구나!',
        desc: '그럼 이따가 있을 학생 공연은...',
        choice: [
            {
                text: '크리스탈 존 매직패스로 바로 들어가야지~!',
                score: [3, 5, 7, 8]
            },
            {
                text: '뒤에서 봐도 좋아! 상품권 받아야지',
                score: [2, 4, 6]
            }
        ]
    },
    {
        question: '그때 마침 휴대폰 알람이 울린다. 보물 찾기를 한다고? 힌트를 따라 서둘러 가보니 다행히도 보물이 남아있었다.',
        desc: '음... 하나만 더 찾아볼까?',
        choice: [
            {
                text: '무조건 다 찾아!',
                score: [2, 5, 8]
            },
            {
                text: '하나 찾았으니 여기서 끝!',
                score: [1, 3, 6]
            }
        ]
    },
    {
        question: '열심히 축제를 즐기다 보니 배가 슬슬 고프다.',
        desc: '맛있는 냄새를 따라가 보니 다양한 푸드트럭들이 보인다. 나의 음식 취향은?',
        choice: [
            {
                text: '든든하고 맛있는 불초밥',
                score: [1, 3, 8]
            },
            {
                text: '시원 달달한 아이스크림 츄러스',
                score: [5, 7]
            }
        ]
    },
    {
        question: '배도 든든하니 공연 전까지 조금만 쉴까?',
        desc: '',
        choice: [
            {
                text: '운정뜰 포토존에서 사진도 찍고 쉬어야지',
                score: [4, 6]
            },
            {
                text: '일 년에 한 번뿐인 수정대동제, 쉴 수 없어!',
                score: [5, 8]
            }
        ]
    },
    {
        question: '어떤 학생이 수정플래닛 999 무대를 보려고 통제구역으로 들어가네? 어..! 거기는 통제구역인데...',
        desc: '이때의 나는?',
        choice: [
            {
                text: '그러면 안 돼! 가서 말해줘야지',
                score: [1, 3, 8]
            },
            {
                text: '모르는 것 같은데.. 현장 스태프한테 전해줘야겠다',
                score: [2, 4, 7]
            }
        ]
    },
    {
        question: '어느새 하늘은 어둑어둑해지고 축제의 끝이 다가온다.',
        desc: '아쉬운 마음에 하늘을 올려다보니 화려한 불꽃들이 성신을 밝히기 시작한다. ',
        choice: [
            {
                text: '지금 이 순간을 눈에 담는다',
                score: [2, 6, 7]
            },
            {
                text: '(카메라를 들고) 일단 찍고 나중에 보자!',
                score: [1, 3, 5]
            }
        ]
    },
    {
        question: '모든 공연이 끝나고 하나둘 집에 가기 시작한다. 학교 정문 쪽으로 많은 인파가 몰린다.',
        desc: '음... 하나만 더 찾아볼까?',
        choice: [
            {
                text: '애프터 파티까지!!! 축제의 여운을 더 느낄래~',
                score: [5]
            },
            {
                text: '나도 사람들을 따라 로데오로 나가야지',
                score: [1, 2, 8]
            },
            {
                text: '조금 돌아가더라도 사람 없는 길을 찾아보자',
                score: [3, 4, 6, 7]
            }
        ]
    },
    {
        question: '집에 들어오자마자 나는?',
        desc: '축제의 여운에 빠져 걷다 보니 어느새 집에 도착했다. 여러 가지 부스부터 공연과 불꽃놀이까지... 하루가 쏜살같이 지나갔다.',
        choice: [
            {
                text: '먼저 씻자',
                score: [1, 3, 8]
            },
            {
                text: '오늘 찍은 사진들 정리해야지',
                score: [2, 8]
            },
            {
                text: '너무 힘들어... 일단 눕자',
                score: [5, 7]
            }
        ]
    }
]

export default AllText